
@import "../../sass/base";

.container-fluid {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../assets/leadin.png");
    background-size: cover;
}

.login-form {
    position: relative;
    top: 30vh;
    width: 100%;
}
